<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :tip="tip"
        :filter="true"
        class="today-course-card"
    >
        <!-- <section style="margin-top:25px"> -->
        <Empty text="开发中..." />
        <!-- </section> -->
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import Empty from '@/components/empty'

export default {
    name: '',
    components: { CardComponent, Empty },
    data() {
        return {
            title: '今日培训',
            tip: '今日培训',
            icon: 'iconfont icon-wodepeixun'
        }
    },
    created() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.today-course-card {
    grid-row-start: span 2;
}
</style>
